<template>
  <div class="dashboard-container">
    <div class="page-container">
      <img class="image" src="@/assets/404.png" alt="">
      <div class="title">404</div>
      <div class="subtitle">Sorry, the page you visited does not exist.</div>
      <el-button class="back-btn" type="primary" @click="backHome">Back Home</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Exception404",
  methods: {
    backHome() {
      this.$emit('closeView', '/admin/index')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .image {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }

  .title {
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }

  .subtitle {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }

  .back-btn {
    margin-top: 24px;
  }
}
</style>